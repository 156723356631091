import React from 'react'
import { Route } from '../../service/type'
import { StationDisplayTemplate } from '../station/StationDisplayTemplate'
import { classNames } from 'primereact/utils'

export interface RouteDisplayTemplateProps {
    route: Route,
    titleFontSize: 1 | 2,
    contentFontSize: 1| 2 | 3
}

export const RouteDisplayTemplate = ({route, titleFontSize, contentFontSize}: RouteDisplayTemplateProps) => {
  return (
    <div className="mx-3 my-1 flex-grow-1 flex-wrap">
        <div>
            <div className={classNames('font-bold text-900 text-primary my-3', {'text-lg' : titleFontSize==1}, {'text-xl' : titleFontSize==2} )}>{route.name}</div>
            <div className={classNames("flex flex-row gap-3", {'text-sm' : contentFontSize==1}, {'text-lg' : contentFontSize==3}, {'text-xl' : contentFontSize==3})} style={{"color": "#000000"}}>
                <StationDisplayTemplate station={route.fromStation} />
                <div className='align-content-center'>
                <i className="pi pi-angle-double-right" />
                </div>
                {
                <div className='align-content-center'>
                    {
                    route.stops.map(pl =><div>{pl.stop.city.shortName}</div>)
                    }
                </div>
                }
                {
                route.stops.length >0 && 
                <div className='align-content-center'>
                    <i className="pi pi-angle-double-right" />
                </div>
                }
                <StationDisplayTemplate station={route.toStation} />
            </div>
        </div>
    </div>
  )
}


export const SimpleRouteDisplayTemplate = ({route, titleFontSize, contentFontSize}: RouteDisplayTemplateProps) => {
    return (
      <div className="mx-3 my-1 flex-grow-1">
          <div>
              <div className={classNames('font-bold text-900 my-3', {'text-lg' : titleFontSize==1}, {'text-xl' : titleFontSize==2} )}>{route.name}</div>
              <div className={classNames("flex flex-row gap-3", {'text-sm' : contentFontSize==1}, {'text-lg' : contentFontSize==3}, {'text-xl' : contentFontSize==3})}>
                  <StationDisplayTemplate station={route.fromStation} />
                  <div className='align-content-center'>
                    <i className="pi pi-angle-double-right" />
                  </div>
                  <StationDisplayTemplate station={route.toStation} />
              </div>
          </div>
      </div>
    )
  }

  export const LightRouteDisplayTemplate = ({route, titleFontSize}: RouteDisplayTemplateProps) => {
    return (
        <div>
            <div className='flex gap-2 align-items-center'>
                <div>
                    <i className={classNames("pi pi-directions font-bold mr-1", {'text-lg' : titleFontSize==1}, {'text-xl' : titleFontSize==2})}></i>
                </div>
                <div>
                    <div className={classNames("font-bold")}>{route.name}</div>
                    <small className='flex justify-content-start my-1'>
                        {route.fromStation.city.longName} 
                        <i className="pi pi-angle-double-right mx-2" />
                        {route.toStation.city.longName}
                    </small>
                </div>
            </div>
        </div>
    )
  }




