import React from 'react'
import { Occurence } from '../../../service/type'

interface OccurenceDisplayTemplateProps {
    occurence: Occurence
}

export const OccurenceDisplayTemplate =({occurence}: OccurenceDisplayTemplateProps) => {
  return (
    <div>
        <div className='flex gap-2 align-items-center'>
            <div>
                <i className="pi pi-history font-bold mr-1" style={{ fontSize: '1rem' }}></i>
            </div>
            <div>
                <div className='font-bold'>{occurence.label}</div>
                <small>{occurence.description}</small><br/>
            </div>
        </div>
    </div>
  )
}
