import React from 'react'
import { City } from '../../service/type'

interface CityDisplayTemplateProps {
    city: City
}

export const CityDisplayTemplate =({city}: CityDisplayTemplateProps) => {
  return (
    <div>
        <div className='flex gap-2 align-items-center'>
            <div>
                <img alt={city.country} src="./images/flag_bf.png" width="32" className='border-circle' />
            </div>
            <div className=''>
                <div className='font-bold'>{city.longName}</div>
                <small>{city.country}</small><br/>
            </div>
        </div>
    </div>
  )
}
