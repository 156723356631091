import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react'
import { DialogProps } from '../../propsType';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { STATION_TYPE_STATION, STATION_TYPE_STOP, STATIONS_TYPE } from '../../data';
import { useGetCitiesQuery, useStationMutation } from '../../service/travelMgrApi';
import { City, StationType } from '../../service/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faTrafficLight } from '@fortawesome/free-solid-svg-icons';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { isStop, isStopStation } from '../../../utils';
import { Toast } from 'primereact/toast';
import { CityDisplayTemplate } from '../city/CityDisplayTemplate';

export const StationForm = ({visible, setVisible } : DialogProps) => {

    const { data, error, isLoading, status, isError, isFetching } = useGetCitiesQuery()
    const [createStation] = useStationMutation();


    const [selectedStationName, setSelectedStationName] = useState('');
    const [selectedStationType, setSelectedStationType] = useState<StationType | undefined>(STATIONS_TYPE && STATIONS_TYPE.length >0 ? STATIONS_TYPE[0] : undefined);
    const [selectedCity, setSelectedCity] = useState<City | undefined>(data && data.length >0 ? data[0] : undefined);

    const [isCreatingStation, setCreationStation] = useState(false)

    const toast = useRef<Toast>(null);


    const onStationCreation = async () => {
        setCreationStation(true)
		try {
			const createdStation = await createStation({
                name: selectedStationName,
                cityCode: selectedCity?.code,
                stationType: selectedStationType?.code
			}).unwrap();

		} catch (error) {
            setCreationStation(false)
            setVisible(false)
			throw error
		}
        setSelectedStationName("")
        setCreationStation(false)
        setVisible(false)
	};


    const cityOptionTemplate = (city: City) => {
        return (
            <CityDisplayTemplate city={city} />
        );
    };

    const selectedCityTemplate = (city: City, props: any) => {
        
        if(city == undefined && data && data.length > 0) {
            setSelectedCity(data[0])
        }
        
        if (city) {
            return (
                <CityDisplayTemplate city={city} />
            );
        }
        
        return <span>{props.placeholder}</span>;
    }

    const stationTypeOptionTemplate = (stationType: StationType) => {
        const isStop = stationType.code == STATION_TYPE_STOP
        return (
            <div className='font-bold'>
                <FontAwesomeIcon className='mr-2' icon={isStop ? faTrafficLight : faBuilding } />
                <span>{stationType.label}</span>
            </div>
        );
    }

    const selectedStationTypeTemplate = (stationType: StationType, props: any) => {
        if (stationType) {
            const isStop = stationType.code == STATION_TYPE_STOP
            return (
                <div className='font-bold'>
                    <FontAwesomeIcon className='mr-2' icon={isStop ? faTrafficLight : faBuilding } />
                    <span>{stationType.label}</span>
                </div>
            );
        }
        
        return <span>{props.placeholder}</span>;
    }

    const footerContent = (
        <div>
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" size='small' icon="pi pi-check" onClick={onStationCreation} autoFocus />
        </div>
    );



    return (

        <Dialog
            header="Enregistrer une gare/escale" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false); }} 
            footer={footerContent}
            className='sm:w-10 md:w-6 lg:w-5 xl:w-4 zoomin animation-duration-400'
            >
                <p className="m-0">
                    <div className='card'>
                        {isCreatingStation && 
                            <div className="card text-center" style={{minHeight: '50px'}}>
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        }
                        
                        <div className='my-4 flex flex-column gap-6'>
                            <div>
                                <FloatLabel>
                                    <InputText id="stationName" value={selectedStationName} onChange={(e) => setSelectedStationName(e.target.value)} className='w-full'/>
                                    <label htmlFor="stationName">Nom de la gare/escale</label>
                                </FloatLabel>
                            </div>
                            <div>
                                <FloatLabel>
                                    <Dropdown 
                                        inputId="stationType" 
                                        value={selectedStationType} 
                                        onChange={(e) => setSelectedStationType(e.value)} 
                                        options={STATIONS_TYPE} 
                                        optionLabel="label" 
                                        className="w-full"
                                        itemTemplate={stationTypeOptionTemplate}
                                        valueTemplate={selectedStationTypeTemplate}
                                        />
                                    <label htmlFor="stationType">Gare ou escale ?</label>
                                </FloatLabel>
                            </div>
                            <div>
                                <FloatLabel>
                                    <Dropdown 
                                        loading={isLoading || isFetching} inputId="city" 
                                        value={selectedCity} onChange={(e) => setSelectedCity(e.value)} 
                                        options={data} optionLabel="longName" className="w-full min-h-1"
                                        itemTemplate={cityOptionTemplate}
                                        valueTemplate={selectedCityTemplate}
                                        />
                                        <label htmlFor="city">Localisation</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>
                </p>
                <Toast ref={toast} />
        </Dialog>

        
    )
}
