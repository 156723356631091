import { Button } from 'primereact/button';
import React, { useCallback, useRef, useState } from 'react'
import { DialogProps } from '../../propsType';
import { STATION_TYPE_STOP, STATIONS_TYPE } from '../../data';
import { useGetAllStationQuery, useRouteMutation} from '../../service/travelMgrApi';
import { Station, StopsOrder } from '../../service/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ListBox } from 'primereact/listbox';
import { SpeedDial } from 'primereact/speeddial';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { faBuildingFlag, faSignsPost } from '@fortawesome/free-solid-svg-icons';
import { RouteDiagram } from './RouteDiagram';
import { Message } from 'primereact/message';

export const RouteForm = ({visible, setVisible } : DialogProps) => {

    const [isCreatingRoute, setCreatingRoute] = useState(false)
    const { data, error, isLoading, status, isError, isFetching } = useGetAllStationQuery()
    const [createRoute] = useRouteMutation();

    const [stations, setStations] = useState<Station[]>([])
    
    const op = useRef<OverlayPanel>(null);
    

    const onRouteCreation = async () => {
        setCreatingRoute(true)

		try {
            const middleStations = [...stations].slice(1, -1)

            const stopOrders = middleStations.map((st, index) => {
            return {
                stopId: st.id,
                order: index
            } as StopsOrder
                
            })
			const createdRoute = await createRoute({
                name : stations[0].city.longName+" - "+stations[stations.length-1].city.longName,
                fromStationId: stations[0].id,
                toStationId: stations[stations.length-1].id,
                stopsOrder: stopOrders
            })

		} catch (error) {
            setCreatingRoute(false)
            setVisible(false)
			throw error
		}
        setStations([])
        setCreatingRoute(false)
        setVisible(false)
	};




    
    const stationLists = useCallback(() => {
        return data?.filter( station => !(stations.map(s => s.id).indexOf(station.id) !== -1) );
    }, [data, stations])


    const removeStation = (stationId: number | undefined) => {
        if(stationId) {
            const stationss = stations.filter(st => st.id !== stationId)
            setStations(stationss)
        }
    }

    const moveLeft =  useCallback((stationId: number | undefined) => {
        const stationsZ = [...stations]
        const toSwapIndex = stationsZ.findIndex(st => st.id == stationId)
        const previousIndex = toSwapIndex-1
        if(previousIndex >=0) {
            const temp = stationsZ[toSwapIndex];
            stationsZ[toSwapIndex] = stationsZ[previousIndex];
            stationsZ[previousIndex] = temp;
            setStations(stationsZ)
        }
    }, [stations])

    const moveRight =  useCallback((stationId: number | undefined) => {
        const stationsZ = [...stations]
        const toSwapIndex = stationsZ.findIndex(st => st.id == stationId)
        const nextIndex = toSwapIndex+1
        if(nextIndex <=stationsZ.length-1) {
            const temp = stationsZ[toSwapIndex];
            stationsZ[toSwapIndex] = stationsZ[nextIndex];
            stationsZ[nextIndex] = temp;
            setStations(stationsZ)
        }
    }, [stations]) 
    


    const listTemplate = (station: Station) => {
        const isStop = station.stationType == STATION_TYPE_STOP
        const severity = isStop ? "warning" : "success"

        return <div>
            <div className='flex gap-2 align-items-center'>
            <div>
                <Button disabled icon={<FontAwesomeIcon icon={isStop ? faSignsPost : faBuildingFlag } size='lg' />} rounded severity={severity} />
            </div>
            <div className=''>
                <div className='font-bold'>{station.name}</div>
                <small>{station.city.longName}</small><br/>
                <small>{station.city.country}</small>
            </div>
            
            </div>
        </div>
    }

    const footerContent = (
        <div>
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" size='small' icon="pi pi-check" onClick={onRouteCreation} autoFocus />
        </div>
    )


    return (

        <Dialog
            header="Créer un itinéraire" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false); }}
            footer={footerContent}
            className='sm:w-11 md:w-8 lg:w-9 xl:w-10 zoomin animation-duration-500'
            >
                <p className="m-0">
                    <div className='card'>
                        {isCreatingRoute && 
                            <div className="card text-center" style={{minHeight: '50px'}}>
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        }
                        
                        <Button size='small' type="button" className='mt-1' outlined icon="pi pi-plus" label='Nouvel itinéraire' onClick={(e) => { if(op != null && op.current != null) op.current.toggle(e)}} />
                        <OverlayPanel ref={op}>
                            <Message className='mb-4' severity="info" text="Sélectionnez les gares et les escales dans l'ordre pour composer l'itinéraire." style={{maxWidth: '300px'}} />
                            <ListBox options={stationLists()} filter itemTemplate={listTemplate} optionLabel='name' listStyle={{ maxHeight: '350px' }} onChange={e => setStations([...stations, e.value])}/>
                        </OverlayPanel>

                        <div className='my-4 flex flex-column gap-6 justify-content-center font-medium border-dashed surface-border border-round surface-ground h-15rem'>
                            <div className=''>
                                <RouteDiagram stations={stations} actions={{removeStation: removeStation, moveLeft: moveLeft, moveRight: moveRight}} animated/>
                            </div>
                        </div>
                    </div>
                </p>
        </Dialog>

        
    )
}
