import { MenuItem } from 'primereact/menuitem'
import { Steps } from 'primereact/steps'
import React, { useCallback, useRef } from 'react'
import { Station } from '../../service/type'
import { STATION_TYPE_STOP } from '../../data'
import { faBuildingFlag, faSignsPost } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SpeedDial } from 'primereact/speeddial'
import { classNames } from 'primereact/utils'


export interface RouteDiagramProps {
    stations: Station[],
    animated?: boolean,
    actions?: RouteDiagramActionsProps
}

export interface RouteDiagramActionsProps {
    removeStation: (stationId: number) => void,
    moveLeft : (stationId: number) => void,
    moveRight : (stationId: number) => void,
}

export const RouteDiagram = ({stations, animated, actions} : RouteDiagramProps) => {


    const speedDialCustumCss = `
    .p-speeddial-button.p-button.p-button-icon-only {
        width: 2.5rem;
        height: 2.5rem;
      }
    `;

    const travelStations = useCallback(() : MenuItem[] | undefined => {
        return stations
            .map((st, i) => {
                const isStop = st.stationType == STATION_TYPE_STOP
                const icon = <FontAwesomeIcon icon={isStop ? faSignsPost : faBuildingFlag } size='1x' />
                return {
                    icon: icon,
                    data: st,
                    template: (item) => travelStationRender(item, i, i!=0),
                } as MenuItem
            })

    }, [stations])


        
    const travelStationRender = (item: MenuItem, itemIndex: any, displayDuration: boolean) => {
        const isStop = item.data.stationType == STATION_TYPE_STOP
        const buttonStyle = isStop ? "p-button-warning" : "p-button-success"
        return (
            <div className='flex flex-grow-1 justify-content-center' style={{ marginTop: '-35px' }}>
                <div className='flex flex-column flex-grow-1 justify-content-center'>
                    <div className='flex flex-column flex-grow-1 align-items-center'>
                        <div className={classNames(animated && 'fadeinup animation-duration-1000 animation-linear')} style={{ marginTop: '-30px'}}>{item.data.name}</div>
                    </div>
                    {
                    displayDuration && 
                        <div style={{marginLeft :'-25px', marginBottom: '20px'}}>
                        <i className="pi pi-stopwatch"></i> 1h30</div> 
                    }
                </div>
                <SpeedDial className={classNames(animated && 'fadeindown animation-duration-1000 animation-linear')} model={dialItems(item.data)} showIcon={item.icon}  rotateAnimation={false} radius={70} type="semi-circle" direction="down" buttonClassName={buttonStyle}  />
            
            </div>
            );
    }

    const dialItems = useCallback((station: Station) : MenuItem[] => {

        if(!actions) {
            return []
        }

        const dialItemss: MenuItem[] = []
        const index = stations.findIndex(st => st.id === station.id)
        if(index < stations.length-1) {
            dialItemss.push({
                label: 'Right',
                icon: 'pi pi-arrow-right',
                command: () => {
                    if(actions)  actions.moveRight(station.id)
                }
            },)
        }

        dialItemss.push({
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
                if(actions)  actions.removeStation(station.id)
            }
        }  )
        

        if(index >0) {
            dialItemss.push({
                label: 'Left',
                icon: 'pi pi-arrow-left',
                command: () => {
                    if(actions)  actions.moveLeft(station.id)
                }
            })
        }

        return dialItemss
    }, [stations])


  return (
    <div>
        <Steps model={travelStations()} readOnly={false} className="m-2 pt-4" />
        <style>{speedDialCustumCss}</style>
    </div>
  )
}

