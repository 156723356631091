import React from 'react'
import { Station } from '../../service/type'

export interface StationDisplayTemplateProps {
    station: Station
}

export const StationDisplayTemplate = ({station} : StationDisplayTemplateProps) => {
  return (
    <div>
        <div className='flex gap-2 align-items-center'>
            <div>
                <img alt={station.city.country} src="./images/flag_bf.png" width="32" className='border-circle' />
            </div>
            <div className=''>
                <div className='font-bold'>{station.city.longName}</div>
                <small>{station.name}</small><br/>
                <small>{station.city.country}</small>
            </div>
        </div>
    </div>
  )
}

