export const formatDateToTime = (date: Date) : string => {
    return (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +  (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
}

export const formatDate = (date: Date) : string => {
    return date.getFullYear()
        +(date.getMonth() +1 < 10 ? "0" : "")
        +(date.getUTCMonth()+1)
        +(date.getDate() < 10 ? "0" : "")
        +date.getDate()
}

export const removeDuplicate = (data: string[]) : string[] => {
    return Array.from(new Set(data))
}