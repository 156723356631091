import { Dialog } from "primereact/dialog";
import { useGetPlanningTravelQuery } from "../../service/travelMgrApi";
import { Planning } from "../../service/type";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export interface TravelDetailsProps {
    planning?: Planning,
    visible: boolean,
    setVisible: (currentState: boolean) => void
}

export const TravelDetails = ({planning, visible, setVisible } : TravelDetailsProps) => {

    const { data: travelQData, error: travelQError, isLoading: travelQLoading, status: travelQStatus, isError: travelQIsError, isFetching: travelQIsFetching } = useGetPlanningTravelQuery({planningId: planning?.id})


    return (
        
        <Dialog
            header={"Voyage "+ planning?.route.name} 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false); }}
            //footer={footerContent}
            className='w-12 md:w-10 xl:w-8 2xl:w-6 zoomin animation-duration-500'>
                <p className="m-0 card">
                <DataTable value={travelQData} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="startDate" header="Date début"></Column>
                    <Column field="startTime" header="Heure début"></Column>
                    <Column field="endDate" header="Date Fin"></Column>
                    <Column field="endTime" header="Heure Fin"></Column>
                </DataTable>
                </p>
        </Dialog>

    )

}