import React, { ReactNode, useState } from 'react'
import { useDeletePlanningMutation, useGetCompanyPlanningQuery } from '../../service/travelMgrApi'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Planning } from '../../service/type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import { faChild, faPersonWalkingLuggage, faRoute } from '@fortawesome/free-solid-svg-icons'
import { Chip } from 'primereact/chip'
import { DataView } from 'primereact/dataview'
import { classNames } from 'primereact/utils'
import { Card } from 'primereact/card'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { ScheduleForm } from './ScheduleForm'
import { RouteDisplayTemplate } from '../route/RouteDisplayTemplate'
import { OccurenceDisplayTemplate } from './occurence/OccurenceDisplayTemplate'
import { PriceComponent } from './Price'
import { TravelDetails } from '../travel/TravelDetails'

export const ScheduleHomePage = () => {

    const {data, error, isLoading, status, isError, isFetching} = useGetCompanyPlanningQuery()
    const [deletePlanningApi] = useDeletePlanningMutation()
    const [visible, setVisible] = useState<boolean>(false)
    const [travelDetailsDialogvisible, setTravelDetailsDialogVisible] = useState<boolean>(false)
    const [selectedPlanning, setSelectedPlanning] = useState<Planning>()


    const onplanningDeletion = async (planningId : number) => {
      try {
          const result = await deletePlanningApi(planningId).unwrap()
      } catch (error) {
          throw error
      }
  }

    const departureTimesTemplates = (rowData: Planning) => {
        return (
            <div className='flex flex row gap-1 flex-wrap'>
              {[... rowData.times].sort((a, b) => a.localeCompare(b)).map(depTime => <Chip label={depTime} className='bg-primary' style={{color: '#000000'}} pt={{label : {
                className: 'my-chip-margin'
              }}} />)}
            </div>
        );
      };
    
      const emptyPlanning = () => {
        return <div className='flex flex-column align-items-center'>
          <div className='my-2'>
            Aucun programe de voyage
          </div>
          <div className='my-2 flex gap-3'>
            <Button label="Créer un planning" size='small' icon="pi pi-calendar" onClick={() => setVisible(true)}/>
          </div>
        </div>
      }
    

      const routeCardTitle = () => {
        return <div className='flex justify-content-between flex-wrap'>
            <div>Gestion des horaires</div>
            <div className='flex align-items-center justify-content-center'>
                <Button size='small' type="button" className='mt-1' outlined icon="pi pi-plus" label='Plannifier un voyage' onClick={() => setVisible(true)} />
            </div>
        </div>
    }

    const header = () => {
      return <div className="flex flex-row-reverse">
          <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText placeholder="Search" type="text" className="p-inputtext-sm w-8rem sm:w-auto" />
          </IconField>
      </div>
    }

      const itemTemplate = (planning: Planning, index: number) => {
        return <div className="col-12" >
            <div className={classNames("flex flex-row hover:bg-primary-50 cursor-pointer", { 'border-top-1 border-bottom-1 surface-border': true })} onClick={(e) => {setSelectedPlanning(planning); setTravelDetailsDialogVisible(true)}}>
                <div className="bg-gray-200 px-2 font-bold align-content-center">
                   # {index+1}
                </div>

                <RouteDisplayTemplate route={planning.route} titleFontSize={2} contentFontSize={1}/>

                <Divider className='my-5' layout='vertical' />

                <div style={{color: '#000000'}} className='flex flex-column p-button-warning flex-grow-1 align-content-center justify-content-center align-items-center'>
                  <div className='text-center my-3'>
                    <OccurenceDisplayTemplate occurence={planning.occurrence} />
                  </div>
                  <div>{departureTimesTemplates(planning)}</div>
                </div>

                <Divider className='my-5' layout='vertical' />

                <div className='flex flex-grow-1 flex-column align-content-center justify-content-around align-items-center mr-2 '>
                  {planning.prices.map(price => <PriceComponent price={price} />)}
                </div>
                
                <div className="bg-gray-100 px-2 font-bold align-content-center">
                  <div className='flex flex-column gap-2'>
                      <Button size='small' icon="pi pi-pencil" text  rounded  aria-label="Filter"  severity='contrast' />
                      <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='contrast' onClick={() => onplanningDeletion(planning.id)} />
                  </div>
                </div>
            </div>
        </div>   
    }


  const listTemplate = (plannings: Planning[]) : ReactNode[] => {
    return plannings.map((planning, index) => itemTemplate(planning, index))
  }


  return (
    <Card title={routeCardTitle} className='shadow-none'>
        {(isLoading || isFetching) &&
            <div className="card text-center">
                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
        }

        { data && <DataView value={data} listTemplate={listTemplate} header={header()}/>}

        {
          data && data.length ==0 &&
          emptyPlanning()
        }
        
        <ScheduleForm visible={visible} setVisible={setVisible} />
        <TravelDetails visible={travelDetailsDialogvisible} setVisible={setTravelDetailsDialogVisible} planning={selectedPlanning}/>
    </Card>
  )
}
