import { icon, IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "primereact/chip"
import { faChild, faPersonWalkingLuggage } from "@fortawesome/free-solid-svg-icons"
import { Price } from "../../service/type"

export interface PriceProps {
    price: Price
}

const PriceContent = ({price}: PriceProps) => {
    return <>
      <span className="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={price.priceCategory == "CHILD" ? faChild : faPersonWalkingLuggage} className='' size='xl' />
        </span>
      <span className="ml-2 font-bold text-red-500">{price.amount.toLocaleString()} <small>fcfa</small> </span>
    </>
  }

export const PriceComponent = ({price}: PriceProps) => {
    return <Chip template={<PriceContent price={price} />} pt={{
        root: {
          className: "pl-0"
        }
      }} />
}







 