import { useRef } from 'react';
import './App.css';
import { addLocale, locale, PrimeReactProvider } from 'primereact/api';
import { Menubar } from 'primereact/menubar';
import {  faCubes, faRoute, faRankingStar } from '@fortawesome/free-solid-svg-icons'

import "primereact/resources/themes/lara-light-cyan/theme.css";  //theme

//import "primereact/resources/themes/lara-dark-cyan/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import { MenuItem } from 'primereact/menuitem';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';
import StationHomePage from './app/component/station/StationHomePage';
import { ScheduleHomePage } from './app/component/schedule/ScheduleHomePage';
import { RouteHomePage } from './app/component/route/RouteHomePage';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Toast } from 'primereact/toast';
import frLocal from './app/local/fr.json'



function App() {

  locale('en');
  addLocale('fr', frLocal)

  const css = `
        .my-chip-margin {
          margin-top: 0.1rem;
          margin-bottom: 0.1rem;
          }
    `;

    const cardCustumCss = `
    .my-card-padding {
      padding: 0rem 0;
      }
    `;

  const toast = useRef<Toast>(null);


  const items: MenuItem[] = [
    {label: 'Tableau de board', icon: 'pi pi-fw pi-chart-line'},
    {label: 'Horaires de voyage', icon: 'pi pi-fw pi-calendar-clock'},
    {label: 'Voyage', icon: <FontAwesomeIcon icon={faRoute} className='mr-2 text-gray-400' />},
    {label: 'Courier', icon: <FontAwesomeIcon icon={faCubes} className='mr-2 text-gray-400' />},
    {label: 'Marketing', icon: <FontAwesomeIcon icon={faRankingStar} className='mr-2 text-gray-400' />}
  ]


  const logo = <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" height="40" className="mr-2"></img>
  const menuBarEnd = (
    <div className="flex align-items-center gap-2">
      <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText placeholder="Search" type="text" className="w-8rem sm:w-auto p-inputtext-sm" />
        </IconField>
        <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
    </div>
);

return (
    <PrimeReactProvider>
      <div className='bg-gray-200'>
        <div className="mx-3 my-3">

          <Menubar model={items} start={logo} className='bg-white mb-3' end={menuBarEnd} />

          <Card className='border-round-xl p-2' pt={{content : {
            className: 'my-card-padding'
            }}}>
            <TabView className='border-round-xl'>
              <TabPanel header="Gestion des horaires">
                <ScheduleHomePage />
              </TabPanel>
              <TabPanel header="Itinéraire">
                  <RouteHomePage />
              </TabPanel>
              <TabPanel header="Gares & escales">
                <StationHomePage />
              </TabPanel>
            </TabView>
          </Card>

        </div>
        <style>{cardCustumCss}</style>
        <style>{css}</style>
        <Toast ref={toast} />
      </div>
    </PrimeReactProvider>
  );
}

export default App;
