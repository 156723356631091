import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { ReactNode, useState } from "react"
import { RouteForm } from "./RouteForm"
import { useDeleteRouteMutation, useGetAllRouteQuery } from "../../service/travelMgrApi"
import { RouteDiagram } from "./RouteDiagram"
import { Route, Station } from "../../service/type"
import { DataView } from "primereact/dataview"
import { classNames } from "primereact/utils"
import { InputText } from "primereact/inputtext"
import { ProgressSpinner } from "primereact/progressspinner"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"


export const RouteHomePage = () => {

    const { data, error, isLoading, status, isError, isFetching } = useGetAllRouteQuery()
    const [deleteRouteApi] = useDeleteRouteMutation()

    const [visible, setVisible] = useState<boolean>(false)
    const [filterValue, setFilterValue] = useState<string>()


    const stationListFromRoute = (route: Route) : Station[] => {
        const stationss : Station[] = []
        stationss.push(route.fromStation)
        route.stops.forEach(route => stationss.push(route.stop))
        stationss.push(route.toStation)
        return stationss
    }



    const routeCardTitle = () => {
        return <div className='flex justify-content-between flex-wrap'>
            <div>Gestion des itinéraires</div>
            <div className='flex align-items-center justify-content-center'>
                <Button size='small' type="button" className='mt-1' outlined icon="pi pi-plus" label='Nouvel itinéraire' onClick={() => setVisible(true)} />
            </div>
        </div>
    }


    const itemTemplate = (route: Route, index: number) => {
            return <div className="col-12" >
                <div className="flex flex-row">
                    <div className="bg-gray-200 px-2 font-bold align-content-center">
                       # {index+1}
                    </div>
                    <div className="flex-grow-1">
                        <div className={classNames('flex flex-column xl:align-items-start p-4 gap-4', { 'border-top-1 border-bottom-1 surface-border': true })}>
                            <div className="text-2xl font-bold text-900">{route.name}</div>
                            <div className="flex-grow-1  justify-content-center w-full zoomin animation-duration-1000" style={{ marginTop: '50px' }}>
                                <RouteDiagram stations={stationListFromRoute(route)} animated={false}/>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100 px-2 font-bold align-content-center">
                    <div className='flex flex-column gap-2'>
                        <Button size='small' icon="pi pi-pencil" text  rounded  aria-label="Filter"  severity='contrast' />
                        <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='contrast' onClick={() => onStationDeletion(route.id)} />
                    </div>
                    </div>
                </div>
            </div>
                
        }
    
    const header = () => {
        return <div className="flex flex-row-reverse">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText placeholder="Search" type="text" className="p-inputtext-sm w-8rem sm:w-auto" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} />
            </IconField>
        </div>
    };

    const listTemplate = (routes: Route[]) : ReactNode[] => {
        if (!routes || routes.length === 0) return []

        let list = routes.map((route, index) => {
            return itemTemplate(route, index)
        })
        return list
    }

    const filterRoute = (routes : Route[]) : Route[] => {
        if(!filterValue || filterValue.trim() == '') {
            return routes
        }
        const result = routes.filter(route => route.name.toLowerCase().includes(filterValue.toLowerCase()))
        console.log(result)
        return result
    }

    const onStationDeletion = async (routeId : number) => {
        try {
            const result = await deleteRouteApi(routeId).unwrap()
        } catch (error) {
            throw error
        }
    }


    return(
        <Card title={routeCardTitle} className='shadow-none'>

            {(isLoading || isFetching) &&
                <div className="card text-center">
                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            }
            {data && <DataView value={filterRoute(data)} listTemplate={listTemplate} dataKey="id" header={header()} paginator={true} rows={3} alwaysShowPaginator={false} emptyMessage="Aucun itineraire à afficher" />}
            <RouteForm visible={visible} setVisible={setVisible} />
        </Card>
    )
}